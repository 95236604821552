import React from 'react';
import ReactDOM from "react-dom";
import MaterialTable from 'material-table';

export default class ArtistsDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'No', field: 'row', grouping: false },
        {
          title: 'Avatar',
          field: 'avatar_url',
          export: false,
          width: 10,
          grouping: false,
          sorting: false,
          filtering: false,
          render: rowData => (
            // console.log('avatar_url');
            <img
              style={{ height: 40, borderRadius: '50%' }}
              src={rowData.avatar_url}
            />
          ),
        },
        { title: 'Name', field: 'name', width: '500', grouping: false },
        { title: 'Email', field: 'email', grouping: false },
        { title: 'Phone', field: 'phone_number', grouping: false },
        { title: 'Username', field: 'username', grouping: false },
        // { title: 'Confirmed', field: 'confirmed' },
        { title: 'Processed at', field: 'processed_verification_at' },
        { title: 'Processed by', field: 'processed_verification_by' },
        // { title: 'Last Sign at', field: 'current_sign_in_at', searchable: false, grouping: false, width: '500' },
        { title: 'Register at', field: 'created_at', searchable: false, grouping: false, width: '500' },
      ],
      data: null,
      page: null,
      totalCount: null
    }

    this.tableRef = React.createRef();
  }

  render () {
    return (
      <MaterialTable
        title={this.props.title ? this.props.title : "Artists"}
        tableRef={this.tableRef}
        columns={this.state.columns}
        data={query =>
          new Promise((resolve, reject) => {
            let url = `${this.props.path}?`
            // console.log('params', query)
            url += 'per_page=' + query.pageSize
            url += '&q=' + query.search
            url += '&page=' + (query.page + 1)
            fetch(url)
              .then(response => response.json())
              .then(result => {
                // console.log('row data', result.accounts.data)
                resolve({
                  data: result.users,
                  page: result.pagination.page - 1,
                  totalCount: result.pagination.count,
                })
              })
          })
        }

        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
          }
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 30, 50, 100, 150, 300, 500],
          exportButton: true,
          sorting: true,
          search: true,
          grouping: true
        }}
        onRowClick={(event, rowData) => Turbolinks.visit(rowData.user_url)}
      />

    );
  }

}

