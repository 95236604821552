import React from 'react';
import ReactDOM from "react-dom";
import MaterialTable from 'material-table';

export default class TransfersTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      columns: [
      	{ title: 'Ref Number', field: 'ref_number'},
        // { title: 'Transaction ID', field: 'transaction_id'},
        { title: 'Sender', field: 'sender'},
        { title: 'Receiver', field: 'receiver'},
        { title: 'Amount', field: 'amount', grouping: false},
        // { title: 'Currency', field: 'currency'},
        // { title: 'Confirmation', field: 'confirmation'},
        // { title: 'Status', field: 'status'},
        { title: 'Date', field: 'date' }
      ],
      data: null,
      page: null,
      totalCount: null
    }

  }

  render () {
    return (
      
      <MaterialTable
        title="Transfers List"
        tableRef={this.tableRef}
        columns={this.state.columns}
        data={query =>
          new Promise((resolve, reject) => {
            let url = '/api/transfers?'
            console.log('params', query)
            
            url += 'per_page=' + query.pageSize
            url += '&q=' + query.search
            url += '&page=' + (query.page + 1)
            if (typeof this.props.user_id !== 'undefined') {
              url += '&user_id=' + this.props.user_id
            }
            
            fetch(url)
              .then(response => response.json())
              .then(result => {
                // console.log('row data', result.accounts.data)
                resolve({
                  data: result.transfers,
                  page: result.pagination.page - 1,
                  totalCount: result.pagination.count,
                })
              })
          })
        }

        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
          }
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 50, 100],
          exportButton: true,
          sorting: true,
          search: true,
          grouping: true

        }}
        onRowClick={(event, rowData) => Turbolinks.visit(rowData.url)}
      />
      

    );
  }

}

