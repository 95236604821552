import React from 'react';
// import ReactDOM from "react-dom";
// import MaterialTable from 'material-table';

export default class CardStatistic extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div className="col-sm-6 col-md-3">
        <a href="/id/admin/doctors">
          <div className="quick-stats__item bg-blue">
            <div className="quick-stats__info">
              <h2>3030</h2>
              <small>Total Doctors</small>
            </div>
          </div>
        </a>
      </div>
    );
  }

}

