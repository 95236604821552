import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

function TotalRecord(props) {
  const badgePath = `/api/statistics/${props.kind}/${props.type}.json`;
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchTotalRecord = () => {
    setIsLoading(true);
    fetch(badgePath)
      .then((res) => (res && res.status ? res.json() : Promise.reject(res)))
      .then((res) => {
        if (res) setTotalCount(res.total);
      })
      .catch((err) =>
        console.log("Terjadi kesalahan saat mengambil data temu janji")
      )
      .finally(() => setIsLoading(false));
  };
  
  useEffect(() => {
    // setInterval(function(){ fetchTotalRecord(); }, 10000);
    fetchTotalRecord();
  }, []);

  return (
    <div>
      {isLoading && (
        <div>
          <h2><Skeleton height={20} width={30} /></h2>
        </div>
      )}
      <h2>{totalCount}</h2>
      <small>{props.title}</small>
    </div>
  );
}

export default TotalRecord;
